<template>
	<div class="preloader"></div>
</template>

<script>
	export default {
		name: "Preloader"
	}
</script>

<style scoped>
	.preloader {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #131313;
		z-index: 1000;
	}

	@keyframes spin {
		0% { transform: translate(-50%, -50%) rotate(45deg); }
		100% { transform: translate(-50%, -50%) rotate(405deg); }
	}

	.preloader::before {
		content: "";
		display: block;
		width: 5rem;
		height: 5rem;
		border-right: solid .1rem #feac08;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		animation: spin 1s infinite;
	}
</style>
