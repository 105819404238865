<template>
	<footer>
		<div class="footer-content">
			<div class="socials">
				<a href="https://kaboom2.net/discord" target="_blank">
					<div>
						<svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
							 viewBox="0 0 70 70" enable-background="new 0 0 70 70" xml:space="preserve">
							<path fill="#FFFFFF" d="M35,0C15.7,0,0,15.7,0,35s15.7,35,35,35s35-15.7,35-35S54.3,0,35,0z M45,49.9c0,0-3,0-1.5-1.9
	c4.7-1.3,5.7-5.2,5.7-5.2c-1.5,1-2.9,1.6-4.2,2.1c-1.8,0.7-3.6,1.2-5.2,1.5c-3.4,0.6-6.6,0.5-9.3,0c-2.1-0.4-3.8-0.9-5.3-1.5
	c-0.8-0.3-1.7-0.7-2.7-1.2c-0.1-0.1-0.2-0.1-0.3-0.2c0,0-0.1-0.1-0.1-0.1c-0.6-0.4-1-0.6-1-0.6s0.8,4,5.4,5.3
	c1.6,1.8-1.5,1.8-1.5,1.8c-7.9-0.2-11-5.4-11-5.4c0-11.4,5.2-20.7,5.2-20.7c5.2-3.9,10.1-3.7,10.1-3.7l0.4,0.4
	c-6.5,1.9-9.4,4.7-9.4,4.7s0.8-0.4,2.1-1c3.9-1.7,6.9-2.1,8.2-2.2c0.2,0,0.4-0.1,0.6-0.1c2.2-0.3,4.7-0.3,7.3-0.1
	c3.4,0.4,7.1,1.4,10.8,3.4c0,0-2.8-2.7-8.9-4.5l0.5-0.6c0,0,4.9-0.1,10.1,3.7c0,0,5.2,9.3,5.2,20.7C56,44.5,53,49.7,45,49.9z"/>
							<path fill="#FFFFFF" d="M30,33.4c-1.9,0-3.4,1.6-3.4,3.6c0,2,1.5,3.6,3.4,3.6s3.4-1.6,3.4-3.6S31.8,33.4,30,33.4z"/>
							<path fill="#FFFFFF" d="M40,33.4c-1.9,0-3.4,1.6-3.4,3.6c0,2,1.5,3.6,3.4,3.6c1.9,0,3.4-1.6,3.4-3.6S41.9,33.4,40,33.4z"/>
						</svg>
					</div>
					<div>{{ $t('footer.discord') }}</div>
				</a>
			</div>
			<div class="helpers">
				<h3>{{ $t('footer.helpers') }}</h3>
				<div class="players">
					<div class="player">
						<img src="https://static.kaboom2.net/images/helpers/ircmaan.png" alt="" class="head">
						<span class="nickname">ircmaan</span>
					</div>
					<div class="player">
						<img src="https://static.kaboom2.net/images/helpers/zohan.png" alt="" class="head">
						<span class="nickname">zohan</span>
					</div>
					<div class="player">
						<img src="https://static.kaboom2.net/images/helpers/_PartyHard_.png" alt="" class="head">
						<span class="nickname">_PartyHard_</span>
					</div>
					<div class="player">
						<img src="https://static.kaboom2.net/images/helpers/Corticoid.png" alt="" class="head">
						<span class="nickname">Corticoid</span>
					</div>
					<div class="player">
						<img src="https://static.kaboom2.net/images/helpers/HappyEnd.png" alt="" class="head">
						<span class="nickname">HappyEnd</span>
					</div>
				</div>
			</div>
		</div>
		<div class="copyright">
			<span>kaboom2.net © 2017 - {{new Date().getFullYear()}}. All rights reserved.</span>
			<span class="link"><router-link to="offer">{{ $t('footer.offer') }}</router-link></span>
			<span class="link"><a href="mailto:caunt.official@gmail.com">Email: caunt.official@gmail.com</a></span>
		</div>
	</footer>
</template>

<script>
	export default {
		name: "Footer"
	}
</script>

<style scoped>
	footer {
		width: 100%;
		height: 25rem;
		position: relative;
		background: linear-gradient(to top left, #101847 0%, #430f60 100%);
		color: #fff;
		text-transform: uppercase;
		padding: 12rem 0 1rem;
	}

	footer::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 7rem;
		background: linear-gradient(to bottom, #131313 0%, rgba(19, 19, 19, 0) 100%);
	}

	footer .footer-content {
		display: flex;
		align-items: center;
	}

	footer .socials {
		width: 70%;
		display: flex;
		justify-content: center;
	}

	footer .socials > a {
		display: flex;
		align-items: center;
		margin-right: 3rem;
		opacity: .2;
	}

	footer .socials > a:hover {
		opacity: .6;
		cursor: pointer;
	}

	footer .socials > a:last-child {
		margin-right: 0;
	}

	footer .socials > a div:first-child {
		width: 3rem;
		height: 3rem;
		margin-right: .6rem;
	}

	footer .helpers {
		width: 12rem;
		display: flex;
		flex-direction: column;
		opacity: .5;
	}

	footer .helpers h3 {
		text-align: center;
	}

	footer .helpers .players {
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
	}

	footer .helpers .player {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: .4rem;
		margin-right: .4rem;
	}

	footer .helpers .player:nth-child(3n) {
		margin-right: 0;
	}

	footer .helpers .head {
		width: 3rem;
		height: 3rem;
	}

	footer .helpers .nickname {
		font-size: .6rem;
		text-transform: none;
	}

	footer .copyright {
		position: absolute;
		bottom: 1rem;
		left: 50%;
		transform: translateX(-50%);
		font-size: .6rem;
		width: 100%;
		display: flex;
		justify-content: space-around;
	}

	footer .copyright span {
		opacity: .2;
	}

	footer .copyright span.link:hover {
		opacity: .6;
	}
</style>
