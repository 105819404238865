<template>
	<header>
		<label class="menu-bar" for="bar"><i class="fa fa-bars"></i></label><input id="bar" type="checkbox">
		<ul>
			<li class="menu_logo"><b>Kaboom 2.0</b></li>
			<li><router-link to="/">{{ $t('header.main') }}</router-link></li>
		</ul>
	</header>
</template>

<script>
	export default {
		name: "HeaderOther"
	}
</script>

<style scoped>
	header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 3rem;
		color: #8a9199;
		z-index: 10;
		transition: background .5s;
		background: linear-gradient(to bottom, rgba(45, 28, 51, 0) 0%, rgba(27, 19, 30, 0) 100%);
		display: flex;
		align-items: center;
	}

	header > input[type="checkbox"] {
		display: none;
	}

	header .menu-bar {
		width: 2rem;
		height: 2rem;
		font-size: 2rem;
		margin-left: 1.2rem;
		display: none;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	header .menu-bar:hover {
		color: #fff;
	}

	header ul {
		list-style: none;
		height: 100%;
		display: flex;
	}

	header li {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 1.2rem;
		white-space: nowrap;
	}

	header li:hover {
		color: #fff;
		cursor: pointer;
	}

	header li.menu_logo {
		font-size: 1.4rem;
		color: #fff;
		cursor: default;
	}
</style>
